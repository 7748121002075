
.gallery {
    margin-left: 5rem;
    margin-right: 5rem;
    display: flex;
    justify-content: space-between;
}

.gallery img {
    filter: drop-shadow(0 0 0.75rem black);
}

.gallery-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-text {
    font-size: xxx-large;
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: 3rem;
    word-wrap: break-word;
    max-width: 25rem;
}

@media (max-width: 768px) {
    .top-text {
        margin-top: 2rem;
        max-width: 80vw;
    }
}

.column-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.column-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.work-title {
    margin-top: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 4rem;
    font-size: 1.25rem;
}

