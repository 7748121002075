.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page h1 {
    margin-top: 0;
}

.about-page h4 {
    margin-top: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
}

.art-board {
    width: 50vw;
    height: auto;
}

.blurb {
    letter-spacing: -1px;
    font-size: 1.5rem;
    padding-bottom: 4rem;
}


.art-board-mobile {
    width: 90vw;
    height: auto;
}

.blurb-mobile {
    max-width: 85vw;
    letter-spacing: -1px;
    font-size: 1.5rem;
    padding-bottom: 4rem;
}