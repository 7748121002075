
.bar {
    width: 100%;
    height: 1.5rem;
    margin-bottom: 2rem;
}

.container {
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 5rem;
    width: auto;
    margin-left: 1.5rem;
}

.left-content {
    display: flex;
    flex-direction: row;
}

.insta {
    height: 1.5rem;
    width: auto;
    margin-top: auto;
    margin-left: 1rem;
    margin-right: 1.5rem;
}

.links {
    margin-top: auto;
    display: flex;
    gap: 1rem;

    a {
        text-decoration: none;
        color: black;
        font-size: 24px;
    }

    a:hover {
        text-decoration: underline;
    }
}
