.home-divide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
}

.home-divide-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vw;
}

.link-box {
    background-color: #555;
    position: relative;
    height: 70vh;
    width: 35vw;
    transition: filter 0.5s ease-in-out;
    color: black;
}

.link-box-mobile {
    background-color: #555;
    position: relative;
    height: 35vh;
    width: 80vw;
    transition: filter 0.5s ease-in-out;
    color: black;
}

.link-box:hover {
    filter: brightness(0.8);
}


.midtext {
    font-size: 3rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.il-left {
    background-image: url('../../assets/home/hearts.png');
}

.il-right {
    background-image: url('../../assets/home/board.png');
}

.image-link {
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(0.8);
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(2px);
}
